/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-lg {
  font-size: 2.25rem!important;
}
.icon-checkbox .icon {
  width: 2.4em!important;
  height: 2.4em!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-checkbox .icon svg{
  width: 80%!important;
  height: 80%!important;
}
.quantity-display {
  line-height: 3.4em!important;
  cursor: pointer;
}
.table td, .table th {
  padding: 0.40rem!important;
}
.table {
  font-size: 1.2em!important;
}
.table .italic{
  font-size: 1em!important;
}