ul.dispatch-header {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f1f1f1;
  border: solid 1px #d3d3d3;
  border-radius: 5px;
  display: table;
  width: 100%;
}

ul.dispatch-header > li {
  display: table-cell;
  vertical-align: middle;
}

ul.dispatch-header > li a {
  display: block;
  text-align: center;
  text-decoration: none;
}

ul.dispatch-header > li:active,
ul.dispatch-header > li:hover {
  background-color:#eaeaea;
}

ul.dispatch-header a.toggle-completed,
ul.dispatch-header a.toggle-completed:active,
ul.dispatch-header a.toggle-completed:hover {
  text-decoration: none;
  color: #c8c8c8;
}

ul.dispatch-header a.toggle-completed.completed,
ul.dispatch-header a.toggle-completed.completed:active,
ul.dispatch-header a.toggle-completed.completed:hover {
  text-decoration: none;
  color: #3c763d;
}

.progress-line {
  height: 3px;
  background:orange;
  transition: width .25s;
  z-index:9999;
}

footer.footer-menu {
	position:fixed;
	bottom : 0; 
	left :0; 
	width : 100%; 
}

footer .footer-menu-hidden {
	display : none;
}

footer .footer-menu-show {
	background : #efefef;
}

footer .footer-menu-show .footer-menu-hidden {
	display : inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
}
.locale-container {
  position: relative;
  border: 1px solid #ccc;
  margin-top: 2rem;
  padding: 1rem;
}
.locale-container span:not(.locale-title) .badge:not(.badge-secondary){
  font-size: 150%;
}

.locale-title {
  position: absolute;
  top: -1.5em;
  left: 1em;
  height: 2em;
  display: inline-block;
  background: white;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}