.wrapper {
    border-radius: 4px;
    overflow: hidden;
    --dispatch-header-height: 74.8px;
    padding: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px;
}

.actions {
    display: flex;
}

.firstButton {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--dispatch-header-height);
}
.firstButton svg {
    width: 20rem;
    width: 2rem;
    height: 2rem;
}

.actions > button {
    height: 100%;
    width: var(--dispatch-header-height);
}