.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.vertical-center {
  vertical-align: middle;
}

$dispatch:
 "dispatch-home-frais" 0 123 255 #cee5ff,
 "dispatch-home-sec" 40 167 69 #daebde,
 "reception-nd" 23 162 184 #bfe3e8;

@each $name, $r, $g, $b, $bg in $dispatch {
  .#{$name} {
      padding-bottom: 3rem;
      min-height: 100vh;
      padding-top: 15px;
      background-color : $bg;
      .table-bordered {
        border: 1px solid rgba($r, $g, $b, 0.5);
        th, td{
          border: 2px solid rgba($r, $g, $b, 0.5);
        }
        th{
          background-color: rgba($r, $g, $b, 0.8);
          color: #fff
        }
      }
      .btn-outline-secondary{
        border: 1px solid rgba($r, $g, $b, 0.5);
        background-color: #fff;
        color: rgba($r, $g, $b, 1);
      }
      .bg-success {
        background-color: rgba($r, $g, $b, 1) !important;
      }
      .text-success {
        color: rgba($r, $g, $b, 1) !important;
      }
      .progress-bar {
        background-color: rgba($r, $g, $b, 1) !important;
      }
  }
};




.datatable {
  background-color: #fff;
}